import * as Mixpanel from 'mixpanel-browser';
import { Injectable, effect, inject } from '@angular/core';
import { AppEnvironment } from '../../environments/environment';
import { AuthService } from '../auth/auth.service';
import { filter } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class MixpanelService {
  private mixpanel?: Mixpanel.Mixpanel;
  private readonly authService = inject(AuthService);

  constructor() {
    if (AppEnvironment.prod && AppEnvironment.mixpanelToken) {
      this.mixpanel = Mixpanel.init(
        AppEnvironment.mixpanelToken,
        {
          debug: !AppEnvironment.prod,
          track_pageview: true,
          api_host: "https://api-eu.mixpanel.com"
        },
        'https://api-eu.mixpanel.com/lib.min.js'
      );
    }

    this.authService.user 
      .pipe(
        filter(user => user !== null)
      )
      .subscribe(user => {
        if (this.mixpanel && user) {
          this.mixpanel.identify(user.emailAddress);
        }
      });
  }

  track(event: string, properties: any): void {
    if (!this.mixpanel) return;

    this.mixpanel?.track(event, this.formatNames(properties));
  }

  private formatNames(properties: any): any {
    return Object.fromEntries(
      Object
        .entries(properties)
        .map(([key, value]) => {
          return [key.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase()), value]
        })
    );
  }
}
